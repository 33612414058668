<template>
  <!-- Slider main container -->
  <div class="swiper-container">
    <div ref="swiper" class="swiper">
      <!-- Slides <div class="swiper-slide">Slide {{ activeIndex + 1 }}</div> -->
      <div class="swiper-wrapper" :class="{ 'bounce-slide': bounce }">
        <slot name="slides"></slot>
      </div>
    </div>

    <!-- Navigation arrows -->
    <div class="swiper-button-prev" :class="{ 'visible-arrows': showArrows }" />
    <div class="swiper-button-next" :class="{ 'visible-arrows': showArrows }" />

    <!-- Pagination bullets -->
    <div v-if="showBullets" class="swiper-pagination" />
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css'

export default {
  name: 'SwipperCarrousel',
  props: {
    showArrows: {
      type: Boolean,
      default: true
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    spaceBetween: {
      type: Number,
      default: 0
    },
    slidesPerView: {
      type: Number,
      default: 1
    },
    loopedSlides: Number,
    autoplay: {
      type: [Boolean, Object],
      default: () => {
        return {
          delay: 1500,
          disableOnInteraction: false
        }
      }
    },
    bounce: {
      type: Boolean,
      default: false
    },
    showBullets: Boolean
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  mounted () {
    const SECOND = 1000 // milliseconds

    const initSwiper = () =>
      new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        slidesPerView: this.slidesPerView,
        loopedSlides: this.loopedSlides,
        autoplay: this.autoplay,
        speed: SECOND,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        spaceBetween: this.spaceBetween,
        observer: true,
        // Events
        on: {
          slideChange: (swiper) => {
            this.activeIndex = swiper.realIndex
          }
        }
      })

    initSwiper()
  }
}
</script>

<style lang="sass" scoped>
.swiper-pagination
  height: 0
  bottom: 26px
.swiper-container
  position: relative
  width: 100%
.swiper-button-prev
  left: 0
.swiper-button-next
  right: 0
.swiper
  width: 100%

::v-deep .swiper-pagination-bullet
  background: var(--gray-40)!important
  width: 8px
  height: 8px
  &-active
    background: var(--purple)!important
    border: 2px solid var(--purple-20)
    width: 12px
    height: 12px

.visible-arrows
  display: flex!important

.swiper-button-next, .swiper-button-prev
  display: none
  transition: opacity 0.5s ease
  color: var(--purple)
  border: solid white 25px
  border-radius: 100%
  box-shadow: 0px 7px 7px rgba(131, 80, 230, 0.2)!important
  top: 45%
  &:after
    font-size: initial
  &:hover
    border-color: var(--purple-5)
  &:active
    box-shadow: 0 0 0 4px rgba(255, 255, 255, .5)!important

@media (max-width: 840px)
  .swiper-container
    padding: 0 !important

/**
  Bounce slide
 */
.bounce-slide
  animation: bounce-slide 0.8s cubic-bezier(.45,.05,.55,.95)

@keyframes bounce-slide
  0%
    transform: translateX(30px)
  30%
    transform: translateX(36px)
  60%
    transform: translateX(-10px)
  100%
    transform: translateX(0)
</style>
